import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducers'
/* eslint-disable no-underscore-dangle */

let devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()

if (process.env.NODE_ENV === 'production') {
  devTools = a => a;
}

const store = (window.navigator.userAgent.includes('Chrome') && devTools) ?
  createStore(
    reducer,
    compose(
      applyMiddleware(thunk), devTools
    ),
  ) :
  createStore(
    reducer,
    compose(
      applyMiddleware(thunk)
    ),
  )
/* eslint-enable */
export default store
