import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useFormik } from 'formik';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert } from '@material-ui/lab';
import { IPaymentMethod } from '../../../../../shared/interfaces/PaymentMethod';
import { getApolloError } from '../../../../../shared/utils/apolloError';
import { IUser } from '../../../../../shared/interfaces/User';
import SET_DEFAULT_PAYMENT_FOR_SUBSCRIPTION from '../../../../../graphql/subscription/setDefaultPaymentMethodForSubscription.mutation';
import UPDATE_USER_PROFILE from '../../../../../graphql/user/updateUserProfile.query';
import RoundSpinner from './Spinner';
import GET_PAYMENT_METHOD from '../../../../../graphql/paymentMethod/retrievePaymentMethod.query';
import GET_USER_PROFILE from '../../../../../graphql/user/getUserProfile.query';
import PaymentMethodModal from '../Modals/PaymentMethod';
import { AuthContext } from '../../../../../shared/context/AuthContext';
import { ACTIONS } from '../../../../../shared/context/AuthContext/Constants';

interface IProps {
  user?: IUser;
  onEvent: (ev?: { type: string; data: any }) => void;
}

const PaymentInfo = (props: IProps) => {
  const { user, onEvent } = props;
  const STRIPE_PROMISE = loadStripe(process.env.REACT_APP_STRIPE);

  const { dispatch } = useContext(AuthContext);

  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

  const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] = useState<string>(null);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [defaultSubPaymentMethodId, setDefaultSubPaymentMethodId] = useState<string>(null);
  const [userInfo, setUserInfo] = useState<IUser>(null);

  const [updateUser, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_USER_PROFILE);
  const [updatePaymentMethod, { error: uPmError, loading: uPLoading }] = useMutation(
    SET_DEFAULT_PAYMENT_FOR_SUBSCRIPTION,
  );

  const [getUser, { data: userData, error: userError, loading: userLoading }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
  });
  const { data: pmData, error: pmError, loading: pmLoading } = useQuery(GET_PAYMENT_METHOD, {
    fetchPolicy: 'network-only',
  });

  const loading = userLoading || updateLoading || pmLoading || uPLoading;
  const error = updateError || userError || pmError || uPmError;

  useEffect(() => {
    if (pmData) {
      setPaymentMethods(get(pmData, 'retrieveUserPaymentMethods.paymentMethods', []));
      setDefaultSubPaymentMethodId(get(pmData, 'retrieveUserPaymentMethods.defaultPaymentSub', null));
    }
  }, [pmData]);

  useEffect(() => {
    if (error) {
      setShowErrorMsg(true);
    }
  }, [error]);

  //User Query
  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (userData) {
      const data = get(userData, 'UserProfile', null);
      formik.setFieldValue('email', data.email);
      formik.setFieldValue('firstName', data.firstName);
      formik.setFieldValue('lastName', data.lastName);
      setUserInfo(data);
      dispatch({ type: ACTIONS.SET_USER, user: data });
    }
  }, [userData]);

  const submitForm = () => {
    if (newDefaultPaymentMethod) {
      const values = {
        paymentMethodId: newDefaultPaymentMethod,
        userId: userInfo._id,
      };
      updatePaymentMethod({ variables: { input: { ...values } } }).then(() => getUser());
    }

    const values = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
    };
    updateUser({ variables: { input: { ...values } } }).then(() => getUser());
    onEvent();
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    onSubmit: () => {},
  });

  const onClose = () => {
    setPaymentModal(false);
    setAddPaymentMethod(false);
  };

  const onconfirm = () => {
    setPaymentModal(false);
    setAddPaymentMethod(false);
  };

  const onPaymentMethodSelected = (event) => {
    event.preventDefault();
    setNewDefaultPaymentMethod(event.target.value);
  };

  return (
    <>
      <Elements stripe={STRIPE_PROMISE}>
        <PaymentMethodModal open={addPaymentMethod} onCloseModal={onClose} onConfirm={onconfirm} />
      </Elements>
      <form id='user-form'>
        <h1 className='font-semibold text-2xl pt-10'>
          Payment Info {pmLoading && <RoundSpinner color={'text-black'} />}
        </h1>
        {showErrorMsg && (
          <div className='pt-2'>
            <Alert onClose={() => setShowErrorMsg(false)} severity='error'>
              {getApolloError(error)}
            </Alert>
          </div>
        )}
        <div className='pt-6'>
          <label htmlFor='paymentMethod' className='text-black text-sm font-light'>
            PAYMENT METHOD
          </label>
          <select
            id='paymentMethod'
            disabled={user?.appUserId ? true : false}
            className='bg-gray-50 border border-gray-300 text-black text-sm focus:ring-blue-500
                   focus:border-blue-500 block w-full md:p-3 font-light'
            onChange={onPaymentMethodSelected}
          >
            {paymentMethods.map((pm) => (
              <option key={pm.id} value={pm.id} className='text-xs'>
                {`${pm.card.brand.toUpperCase()} ended in ${pm.card.last4} - Exp. ${pm.card.exp_month}/${
                  pm.card.exp_year
                } ${pm.id === defaultSubPaymentMethodId ? '(default)' : ''}`}
              </option>
            ))}
          </select>
          <div
            className='mt-4 flex justify-center cursor-pointer'
            onClick={() => {
              if (!user?.appUserId) setAddPaymentMethod(true);
            }}
          >
            <p className='text-sm hover:underline hover:underline-offset-2'>+ Add New payment Method</p>
          </div>
        </div>
        <h1 className='font-semibold text-2xl pt-6 pb-6'>
          Member Info {userLoading && <RoundSpinner color={'text-black'} />}
        </h1>
        <div className='flex flex-col gap-4 pb-4'>
          <div className='flex md:flex-row flex-col gap-2'>
            <div className='text-left text-sm space-y-1 w-full'>
              <label className='text-black font-light' htmlFor='first-name'>
                FIRST NAME
              </label>
              <input
                id='first-name'
                name='firstName'
                type='text'
                value={formik.values.firstName}
                required
                onChange={formik.handleChange}
                className='relative block w-full appearance-none rounded-md border-gray-300 px-3 md:py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
              />
              {formik.errors['firstName'] && (
                <span className='text-xs text-[#F44336]'>*{formik.errors['firstName']}</span>
              )}
            </div>
            <div className='text-left text-sm space-y-1 w-full'>
              <label className='text-black-500 font-light' htmlFor='last-name'>
                LAST NAME
              </label>
              <input
                id='last-name'
                name='lastName'
                type='text'
                value={formik.values.lastName}
                required
                onChange={formik.handleChange}
                className='relative block w-full appearance-none rounded-md border-gray-300 px-3 md:py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
              />
              {formik.errors['lastName'] && (
                <span className='text-xs text-[#F44336]'>*{formik.errors['lastName']}</span>
              )}
            </div>
          </div>
          <div className='text-left text-sm space-y-1'>
            <label className='text-black-500 font-light' htmlFor='email-address'>
              EMAIL ADDRESS
            </label>
            <input
              id='email-address'
              name='email'
              type='email'
              value={formik.values.email}
              disabled
              // onChange={formik.handleChange}
              className='bg-gray-200 relative block w-full appearance-none rounded-md border-gray-300 px-3 md:py-4 text-gray-900 placeholder-gray-500 focus:z-10 sm:text-sm'
            />
            {/* {formik.errors['email'] && <span className='text-xs text-[#F44336]'>*{formik.errors['email']}</span>} */}
          </div>
        </div>
        <div className='flex justify-end'>
          <button
            type='button'
            onClick={() => submitForm()}
            disabled={!formik.isValid || loading}
            className='relative flex justify-center rounded-md border border-grayBg bg-[#7a5cff] py-3 
                    px-8 text-sm font-medium text-white hover:bg-subscriptionBtn hover:text-black ocus:outline-none focus:ring-2 
                    focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default PaymentInfo;
