import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { NEW_SUBSCRIPTION_FLOW_ROUTE } from '../../shared/constants/routes';
import LoginRegisterPage from './pages/loginRegister.page';
import styled from 'styled-components';
import { Container, Divider } from '@material-ui/core';
import TopBar from '../../shared/components/TopBar';
import PublicRoute from '../../shared/components/PublicRoute';
import PrivateRoute from '../../shared/components/PrivateRoute';
import SubscriptionSelectionPage from './pages/subscriptionSelection.page';
import SuccessPage from './pages/success.page';
import LayoutPage from '../Auth/components/Layout/LayoutPage';

const AuthContainer = styled(Container)({
  paddingTop: 18,
  paddingBottom: 18,
});
const NewSubscriptionFlowRouter = () => {
  let { path } = useRouteMatch();

  return (
    <LayoutPage>
      <Switch>
        <PublicRoute exact path={`${path}/${NEW_SUBSCRIPTION_FLOW_ROUTE.BASE}`}>
          <LoginRegisterPage />
        </PublicRoute>
        {/* <PrivateRoute exact path={`${path}/${NEW_SUBSCRIPTION_FLOW_ROUTE.SELECT}`}>
          <SubscriptionSelectionPage />
        </PrivateRoute> */}
        {/* <PrivateRoute exact path={`${path}/${NEW_SUBSCRIPTION_FLOW_ROUTE.SUCCESS}`}>
          <SuccessPage />
        </PrivateRoute> */}
      </Switch>
    </LayoutPage>
  );
};

export default NewSubscriptionFlowRouter;
