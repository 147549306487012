import {Redirect, Route} from 'react-router-dom';


const PrivateRoute = (props: any) => {

  const {children, ...rest} = props;
  const token = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={({location}) =>
        token ? (children)
          :
          <Redirect
            to={{pathname: '/', state: {from: location}}}/>
      }
    />
  )
};

export default PrivateRoute;
