import styled from 'styled-components';

interface IProps {
  backgroundImage: any;
}
const ContainerWhiteBackground = styled.div`
  background: #fff;
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ContainerWhiteBackground;
