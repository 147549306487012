import {gql} from '@apollo/client';
import PaymentMethodCardChecksFragment from "./PaymentMethodCardChecks.fragment";


const PaymentMethodCardFragment = {
  fragment: gql`
      fragment PaymentMethodCard on PaymentMethodCard {
          brand
          country
          exp_month
          exp_year
          fingerprint
          funding
          last4
          checks { ...PaymentMethodCardChecks }
      }
      ${PaymentMethodCardChecksFragment.fragment}
  `

}
export default PaymentMethodCardFragment;
