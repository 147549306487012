import { gql } from '@apollo/client';

const REQUEST_EMAIL_VERIFICATION = gql`
    mutation requestEmailVerification($email: String!) {
        RequestEmailVerification(input: {email: $email}) {
            message
        }
    }
`;

export default REQUEST_EMAIL_VERIFICATION;