import { gql } from '@apollo/client';
import StripeProductFragment from '../fragments/StripeProduct.fragment';

export const GET_STRIPE_PRODUCT_SUBSCRIPTION = gql`
  query getStripeProductSubscription {
    getStripeProductSubscription {
      monthly {
        ...StripeProduct
      }
      annual {
        ...StripeProduct
      }
      monthlyGift {
        ...StripeProduct
      }
      annualGift {
        ...StripeProduct
      }
    }
  }
  ${StripeProductFragment.fragment}
`;

export default GET_STRIPE_PRODUCT_SUBSCRIPTION;
