import styled from 'styled-components';

interface IProps {
  color?: string;
  textColor?: string;
}

const Button = styled.button`
  background-color: ${(props: IProps) => props.color ? props.color : 'white'};
  color: ${(props: IProps) => props.textColor ? props.textColor : 'black'};
  font-family: hammer-regular,serif;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  border: none;
  padding: 8px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  :hover {
    cursor: pointer;
  }

  &:disabled {
    color: #BDBDBD;
    background: #EEEEEE;
  }
`;

export default Button;
