import React, { useContext, useEffect, useState } from 'react';
import AuthContainerForm from '../../components/AuthContainerForm';
import { useHistory, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import Title from '../../../../shared/components/styled/Title';
import Subtitle from '../../../../shared/components/styled/Subtitle';
import Button from '../../../../shared/components/Button';

import { LinearProgress } from '@material-ui/core';

import VERIFY_WEB_AUTH from '../../../../graphql/auth/verifyWebAuth.mutation';
import { get } from 'lodash';
import { ACTIONS } from '../../../../shared/context/AuthContext/Constants';
import GET_USER_PROFILE from '../../../../graphql/user/getUserProfile.query';
import { AuthContext } from '../../../../shared/context/AuthContext';
import { ROOT_ROUTES } from '../../../../shared/constants/routes';
import ContainerWhiteBackground from '../../../../shared/components/ContainerWhiteBackground';

const VerifyEmail = () => {
  const params = useParams();
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const [verifyToken, setVerifyToken] = useState<string>(null);
  const [emailVerified, changeEmailVerified] = useState(false);
  const [urlToRedirect, setUrlToRedirect] = useState(null);
  const [
    verifyWebAuth,
    { loading, data: verifyWebAuthData, error: verifyWebAuthError },
  ] = useMutation(VERIFY_WEB_AUTH);
  const [getUser, { data: getUserData }] = useLazyQuery(GET_USER_PROFILE);

  useEffect(() => {
    if (verifyToken) {
      verifyWebAuth({
        variables: { jwtWebAuth: verifyToken },
      })
        .then(async (res) => {
          if (!res.errors) {
            const token = get(res, 'data.VerifyWebAuth.jwtBearer');
            const refreshToken = get(res, 'data.VerifyWebAuth.jwtRefresh');
            if (token && refreshToken) {
              getUser();
              dispatch({ type: ACTIONS.LOGIN, token, refreshToken });
            }
          }
        })
        .catch((e) => console.log(e));
    }
  }, [verifyToken]);

  useEffect(() => {
    if (getUserData) {
      const temp = get(getUserData, 'UserProfile', null);
      if (temp) {
        dispatch({ type: ACTIONS.SET_USER, user: temp });
        if (urlToRedirect) {
          history.push(`/${ROOT_ROUTES.HOME}/${urlToRedirect}`);
        } else {
          history.push('/account-management');
        }
      }
    }
  }, [getUserData]);
  useEffect(() => {
    //@ts-ignore
    if (params && params.token) {
      //@ts-ignore
      if (params.urlToRedirect) {
        //@ts-ignore
        setUrlToRedirect(params.urlToRedirect);
      }
      //@ts-ignore
      setVerifyToken(params.token);
    }
  }, [params]);

  useEffect(() => {
    if (verifyWebAuthData) {
      changeEmailVerified(true);
    }
  }, [verifyWebAuthData]);

  return (
    <ContainerWhiteBackground>
      <AuthContainerForm>
        {verifyWebAuthError ? (
          <>
            <Title>User could not be Verified!</Title>
            <Subtitle>Please login to your account.</Subtitle>
            <Button onClick={() => history.push('/auth/login')}>LOGIN</Button>
          </>
        ) : (
          <>
            {emailVerified && !loading ? (
              <>
                <Title>User Verified!</Title>
                <Subtitle>logging in to your account.</Subtitle>
              </>
            ) : (
              <>
                <Title>Verifying user</Title>
                <LinearProgress />
              </>
            )}
          </>
        )}
      </AuthContainerForm>
    </ContainerWhiteBackground>
  );
};

export default VerifyEmail;
