import {gql} from "@apollo/client";


const SET_DEFAULT_PAYMENT_FOR_SUBSCRIPTION = gql`
  mutation setDefaultPaymentForSubscription($input:SetDefaultPaymentForSubscriptionInput!) {
    setDefaultPaymentForSubscription(input:$input){
      message
    }
  }
`;

export default SET_DEFAULT_PAYMENT_FOR_SUBSCRIPTION;
