import { gql } from '@apollo/client';

const VERIFY_EMAIL = gql`
    mutation verifyEmail($token: String!) {
        VerifyEmail(input: {jwtEmailVerification: $token}) {
            message
        }
    }
`;

export default VERIFY_EMAIL;