import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import CHANGE_PASSWORD_MUTATION from '../../../../graphql/auth/changePassword.mutation';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { getApolloError } from '../../../../shared/utils/apolloError';
import RoundSpinner from '../../../AccountManagement/pages/MyAccount/Components/Spinner';
import { LockClosedIcon } from '@heroicons/react/20/solid';

const ChangePassword = () => {
  const history = useHistory();
  const params: any = useParams();
  const [resetToken, setresetToken] = useState(null);
  const [changePassword, { loading, error, data }] = useMutation(CHANGE_PASSWORD_MUTATION);

  const submitForm = (values: { password: string; repeatPassword: string }) => {
    changePassword({ variables: { password: values.password, token: resetToken } }).then((response) =>
      history.push('/auth/login'),
    );
  };

  const ValidSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    repeatPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    onSubmit: (values) => submitForm(values),
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: ValidSchema,
  });

  useEffect(() => {
    if (params) {
      if (params.token) {
        setresetToken(params.token);
      }
    }
  }, [params]);

  return (
    <>
      <div className='h-full space-y-2 mt-10 w-full'>
        <div className='flex min-h-full items-center justify-center px-2 sm:px-6 lg:px-8'>
          <div className='w-full space-y-4 border-2 border-gray-500/50 rounded-xl px-6 pb-6 text-white bg-black bg-opacity-50'>
            <h2 className='text-left text-3xl font-semibold tracking-tight text-white py-6'>Change your password</h2>
            <div>{error && <Alert severity='error'>{getApolloError(error)}</Alert>}</div>

            <form className='mt-8 space-y-6' onSubmit={formik.handleSubmit}>
              <div className='rounded-md shadow-sm space-y-3'>
                <div className='text-left text-sm space-y-1'>
                  <label className='text-gray-500' htmlFor='password'>
                    Password
                  </label>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    onChange={formik.handleChange}
                    className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {formik.errors['password'] && (
                    <span className='text-xs text-[#F44336]'>*{formik.errors['password']}</span>
                  )}
                </div>
                <div className='text-left text-sm space-y-1'>
                  <label className='text-gray-500' htmlFor='repeatPassword'>
                    Repeat Password
                  </label>
                  <input
                    id='repeatPassword'
                    name='repeatPassword'
                    type='password'
                    autoComplete='current-password'
                    required
                    onChange={formik.handleChange}
                    className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {formik.errors['repeatPassword'] && (
                    <span className='text-xs text-[#F44336]'>*{formik.errors['repeatPassword']}</span>
                  )}
                </div>
              </div>
              <div>
                <button
                  type='submit'
                  disabled={!formik.isValid || loading}
                  className='group relative flex w-full justify-center rounded-md border border-transparent bg-turquoise py-3 
                    px-4 text-sm font-medium text-white hover:bg-turquoiseClear focus:outline-none focus:ring-2 
                    focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
                >
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3`}>
                    {loading ? (
                      <RoundSpinner color={'text-white'} />
                    ) : (
                      <LockClosedIcon
                        className={`h-5 w-5 text-turquoise group-hover:text-indigo-400`}
                        aria-hidden='true'
                      />
                    )}
                  </span>
                  CHANGE PASSWORD
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
