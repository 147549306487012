import React from 'react';
import { IStripeProduct } from '../../../../../shared/interfaces/StripeProduct';
import Present from '../../../../../assets/images/present.png';
import PresentWhite from '../../../../../assets/images/present_white.png';

interface IProps {
  product: IStripeProduct;
  disableBtn: boolean;
  savings?: number;
  btnCaption?: string;
  isGift?: boolean;
  onSubscribe: (mode?: IStripeProduct) => void;
  onGiftSubscription?: (mode?: IStripeProduct) => void;
}

const Card = (props: IProps) => {
  const {
    product,
    disableBtn,
    savings,
    isGift = false,
    btnCaption = 'Subscribe Now',
    onSubscribe,
    onGiftSubscription,
  } = props;

  const temp = product.price.unit_amount_decimal;
  const interval = product.mode;
  const pt = product && product.name.split(' ');
  const title = `${pt[0]} ${pt[1]}`;

  return (
    <div
      className={`flex relative flex-col gap-4 md:gap-10 rounded-lg border ${
        product.mode === 'month' ? 'bg-cardBg text-black' : 'bg-cardPurpleBg text-white'
      } h-full w-full max-w-[290px] max-h-[240px] md:max-w-[350px] md:max-h-[350px] p-6`}
    >
      {product.mode === 'year' && (
        <div className='absolute text-black px-3 py-2 -top-3 rounded text-xs bg-cardYellow'>SAVE {savings}%</div>
      )}
      <div className='text-xl font-bold'>{title}</div>
      <div>
        <span className='text-3xl font-bold'>
          ${temp?.slice(0, temp?.length - 2)}.{temp?.slice(temp?.length - 2)}
        </span>
        /{interval}
      </div>
      <div className='text-xs font-light'>
        {product.mode === 'month'
          ? 'Gain access to our premium content by paying monthly. Cancel anytime.'
          : `Gain access to our premium content by paying annually to save ${savings}%.`}
      </div>
      <div className='flex flex-col space-y-5'>
        <button
          disabled={disableBtn}
          onClick={() => {
            if (isGift) {
              console.log('gift');
              onGiftSubscription(product);
            } else {
              onSubscribe(product);
            }
          }}
          className='flex justify-center rounded-md border border-grayBg py-4 w-full
            px-4 text-sm font-medium text-black bg-white hover:bg-subscriptionBtn focus:outline-none focus:ring-2 
            focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-50'
        >
          {isGift ? (
            <div className='flex flex-row gap-2 items-center'>
              <img className='h-4' src={Present} alt='Present Box' />
              <div className={` text-sm font-medium text-black}`}>Gift this Subscription</div>
            </div>
          ) : (
            btnCaption
          )}
        </button>
      </div>
    </div>
  );
};

export default Card;
