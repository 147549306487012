import {Context, createContext} from 'react'
import {IUser} from "../../interfaces/User";

export interface IAuthContextState {
  user: IUser | null,
  emailForStripeCheckout: string | null,
  isLoading: boolean,
  token: string | null,
  refreshToken: string | null
}

const initialState: IAuthContextState = {
  emailForStripeCheckout: null,
  isLoading: true,
  refreshToken: null,
  token: null,
  user: null
}

const AuthContext: Context<{ state: IAuthContextState, dispatch: any }> = createContext({
  state: initialState,
  dispatch: () => {

  }
});

export default AuthContext;
