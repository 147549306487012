import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import CREATE_PAYMENT_METHOD from '../../../../../graphql/paymentMethod/createPaymentMethod.mutation';
import GET_PAYMENT_METHOD from '../../../../../graphql/paymentMethod/retrievePaymentMethod.query';
import RoundSpinner from '../Components/Spinner';
import { Alert } from '@material-ui/lab';
import { getApolloError } from '../../../../../shared/utils/apolloError';
import { PaymentMethodResult } from '@stripe/stripe-js';
import { IStripeProduct } from '../../../../../shared/interfaces/StripeProduct';
import SmallCard from '../../Subscription/Components/SmallCard';
import GET_PROMOTION_CODE from '../../../../../graphql/stripe/getPromotionCode.query';
import { get } from 'lodash';

interface IForm {
  city: string;
  postal_code: string;
  state: string;
  line1: string;
  line2: string;
  name: string;
  cc_complete: boolean;
}

interface IProps {
  children?: JSX.Element;
  open: boolean;
  btnMessage?: string;
  isGift?: boolean;
  showMembership?: boolean;
  selectedProduct?: IStripeProduct;
  savings?: number;

  onConfirm: (result?: PaymentMethodResult) => void;
  onCloseModal: (result?: PaymentMethodResult, promotionCode?: string) => void;
}

const PaymentMethodModal = (props: IProps) => {
  const {
    selectedProduct,
    showMembership = false,
    savings,
    open,
    btnMessage = 'Save New Card',
    isGift = false,
    onConfirm,
    onCloseModal,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const cancelButtonRef = useRef(null);
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [showPromoInput, setShowPromoInput] = useState<boolean>(false);

  const [stringError, setStringError] = useState<string>(null);
  const [promoCode, setPromoCode] = useState<string>(null);
  const [promoCodeId, setPromoCodeId] = useState<string>(undefined);

  const [showAlert, setShowAlert] = useState<any>({
    severity: 'success',
    message: '',
    showMessage: false,
  });

  const [createPaymentMethod, { data, error, loading: paymentMethodLoading }] = useMutation(CREATE_PAYMENT_METHOD);
  const [getPromotionCode, { loading: loadingCode }] = useLazyQuery(GET_PROMOTION_CODE, {
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const code = get(data, 'GetPromotionCode', null);
      if (code && code.data.length > 0) {
        setPromoCodeId(code.data[0].id);
        setShowAlert({
          severity: 'success',
          message: 'Valid Code',
          showMessage: true,
        });
      } else {
        setShowAlert({
          severity: 'error',
          message: 'Not a Valid Code',
          showMessage: true,
        });
      }
    },
    onError: (error) => {
      setShowAlert({
        severity: 'error',
        message: 'Not a Valid Code',
        showMessage: true,
      });
    },
  });
  const loading = paymentMethodLoading || stripeLoading;

  const IconCheckboxChecked = (props: React.SVGProps<SVGSVGElement>) => {
    return (
      <svg viewBox='0 0 16 16' fill='#00A36C' height='1em' width='1em' {...props}>
        <path
          fill='#00A36C'
          d='M14 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM7 12.414L3.293 8.707l1.414-1.414L7 9.586l4.793-4.793 1.414 1.414L7 12.414z'
        />
      </svg>
    );
  };

  const submitForm = async (values: IForm) => {
    setStripeLoading(true);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: values.name,
        address: {
          city: values.city,
          country: 'US',
          postal_code: values.postal_code,
          state: values.state,
          line1: values.line1,
          line2: values.line2,
        },
      },
    });
    setStripeLoading(false);

    if (!result.error) {
      createPaymentMethod({
        variables: { input: { paymentMethodId: result.paymentMethod.id } },
        refetchQueries: [{ query: GET_PAYMENT_METHOD }],
      })
        .then(() => {
          setStripeLoading(false);
          onCloseModal(result, showAlert.severity === 'error' ? undefined : promoCodeId);
        })
        .catch(() => {
          setStripeLoading(false);
          onCloseModal();
        });
    } else {
      setStringError('Error: Credit card verification');
    }
  };

  const CardSchema = Yup.object().shape({
    giftName: Yup.string(),
    giftEmail: Yup.string().email('Invalid email'),
    city: Yup.string().required('Required'),
    postal_code: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    line1: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    cc_complete: Yup.boolean().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      city: '',
      postal_code: '',
      state: '',
      line1: '',
      line2: '',
      name: '',
      cc_complete: false,
    },
    onSubmit: (values) => submitForm(values),
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: CardSchema,
  });

  const cardOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#000',
        fontSize: '18px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const formHasChanged = (value): boolean => {
    return !!formik.touched[value] && !!formik.errors[value];
  };

  const _handleCardChange = (event) => {
    formik.setFieldValue('cc_complete', event.complete);
  };

  const handlePromotionCode = (e: any) => {
    setPromoCode(e.target.value);
  };

  const resetVars = () => {
    setShowPromoInput(false);
    setShowAlert({
      severity: 'success',
      message: '',
      showMessage: false,
    });
    setPromoCodeId(null);
    setPromoCode(null);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => {
          console.log('close');
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:w-full sm:max-w-3xl py-2'
              >
                <div className='bg-white px-4 py-5'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      {!showMembership && (
                        <Dialog.Title as='h3' className='text-2xl font-semibold leading-6 text-gray-900'>
                          Add New Payment Method
                        </Dialog.Title>
                      )}
                      <div className='pt-2'>
                        {stringError && <Alert severity='error'>{stringError}</Alert>}
                        {error && <Alert severity='error'>{getApolloError(error)}</Alert>}
                      </div>
                      <div className='flex flex-col md:flex-row gap-6'>
                        {showMembership && (
                          <div className='md:w-2/5 mt-4 text-left'>
                            <Dialog.Title as='h3' className='text-2xl font-semibold leading-6 text-gray-900'>
                              Membership
                            </Dialog.Title>
                            <div className='pt-10'>
                              <SmallCard product={selectedProduct} savings={savings} onSubscribe={() => console.log} />
                            </div>
                            <div className='py-6'>
                              {!isGift ? (
                                !showPromoInput ? (
                                  <h1
                                    className='hover:underline underline-offset-1 cursor-pointer'
                                    onClick={() => setShowPromoInput(true)}
                                  >
                                    + Add promotion code
                                  </h1>
                                ) : (
                                  <>
                                    {showAlert && showAlert.showMessage && (
                                      <Alert severity={showAlert.severity}>{showAlert.message}</Alert>
                                    )}
                                    <div className='text-left pt-2 space-y-2 relative'>
                                      <input
                                        name='name'
                                        type='text'
                                        required
                                        onChange={handlePromotionCode}
                                        placeholder='Add promotion code'
                                        className='relative block w-full appearance-none rounded border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                      />
                                      <button
                                        type='button'
                                        onClick={() => getPromotionCode({ variables: { input: { code: promoCode } } })}
                                        className='border-transparent bg-gray-500 px-4 py-0.5 text-sm text-white flex w-full justify-center rounded border'
                                      >
                                        {loadingCode ? (
                                          <div className='flex flex-row gap-2 items-center'>
                                            <RoundSpinner color={'text-white'} />
                                            <div>Checking...</div>
                                          </div>
                                        ) : (
                                          <>Apply Code</>
                                        )}
                                      </button>
                                    </div>
                                  </>
                                )
                              ) : null}
                            </div>
                          </div>
                        )}
                        <div className='mt-4 text-left'>
                          {showMembership && (
                            <Dialog.Title as='h3' className='text-2xl font-semibold leading-6 text-gray-900'>
                              Payment Information
                            </Dialog.Title>
                          )}
                          <form id='payment-form' className='mt-8' onSubmit={formik.handleSubmit}>
                            <label className='text-sm'>CREDIT CARD INFO</label>
                            <CardElement
                              className='py-3 px-1 border rounded font-light'
                              options={cardOptions}
                              onChange={_handleCardChange}
                            />
                            <div className='text-left pt-2'>
                              <input
                                name='name'
                                type='text'
                                required
                                onChange={formik.handleChange}
                                placeholder="Card holder's name *"
                                className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                              />
                              {formik.errors['name'] && (
                                <span className='text-xs text-[#F44336]'>*{formik.errors['name']}</span>
                              )}
                            </div>
                            <div className='space-y-2 pt-4'>
                              <div className='text-left'>
                                <label htmlFor='line1' className='text-sm'>
                                  BILLING ADDRESS
                                </label>
                                <input
                                  name='line1'
                                  type='text'
                                  required
                                  onChange={formik.handleChange}
                                  placeholder='Street Address 1 *'
                                  className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                />
                                {formik.errors['line1'] && (
                                  <span className='text-xs text-[#F44336]'>*{formik.errors['line1']}</span>
                                )}
                              </div>
                              <div className='text-left'>
                                <input
                                  name='line2'
                                  type='text'
                                  onChange={formik.handleChange}
                                  placeholder='Street Address 2'
                                  className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                />
                              </div>
                              <div className='flex flex-col xs:flex-row gap-2'>
                                <div className='text-left'>
                                  <input
                                    name='city'
                                    type='text'
                                    required
                                    onChange={formik.handleChange}
                                    placeholder='City *'
                                    className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                  />
                                  {formik.errors['city'] && (
                                    <span className='text-xs text-[#F44336]'>*{formik.errors['city']}</span>
                                  )}
                                </div>
                                <div className='text-left'>
                                  <input
                                    name='state'
                                    type='text'
                                    required
                                    onChange={formik.handleChange}
                                    placeholder='State *'
                                    className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                  />
                                  {formik.errors['state'] && (
                                    <span className='text-xs text-[#F44336]'>*{formik.errors['state']}</span>
                                  )}
                                </div>
                                <div className='text-left md:max-w-[150px]'>
                                  <input
                                    name='postal_code'
                                    type='text'
                                    required
                                    onChange={formik.handleChange}
                                    placeholder='Zip *'
                                    className='relative block w-full appearance-none rounded border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                  />
                                  {formik.errors['postal_code'] && (
                                    <span className='text-xs text-[#F44336]'>*{formik.errors['postal_code']}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                  <button
                    form='payment-form'
                    type='submit'
                    className='border-transparent bg-[#7a5cff] px-4 py-2 text-sm text-white hover:text-black sm:ml-3 sm:w-auto sm:text-sm flex w-full 
                    justify-center rounded border hover:bg-subscriptionBg focus:outline-none focus:ring-2 focus:ring-turquoise 
                    focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
                    disabled={!formik.isValid || loading}
                  >
                    {loading ? (
                      <div className='flex flex-row gap-2 items-center'>
                        <RoundSpinner color={'text-white'} />
                        <div>Saving...</div>
                      </div>
                    ) : (
                      btnMessage
                    )}
                  </button>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center px-4 py-2 text-sm text-gray-700 hover:underline hover:underline-offset-1
                    sm:ml-3 sm:w-auto sm:text-sm'
                    disabled={loading}
                    onClick={() => {
                      resetVars();
                      onConfirm();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PaymentMethodModal;
