import { gql } from '@apollo/client';

const REQUEST_PASSWORD_CHANGE_MUTATION = gql`
    mutation requestPasswordChange($email: String!) {
        RequestPasswordChange(input: {email: $email}) {
            message
        }
    }
`;

export default REQUEST_PASSWORD_CHANGE_MUTATION;