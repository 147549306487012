import {USER_TYPES} from "../types/user.types";
import {IUser} from "../../shared/interfaces/User";


export interface IUserState {
  emailForStripeCheckout: string,
  user: IUser,
  error: string | null
  loading: boolean
}


const initialState: IUserState = {
  emailForStripeCheckout: null,
  user: null,
  error: null,
  loading: false,
}

const UserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_TYPES.ADD_EMAIL_FOR_STRIPE:
      return {
        ...state,
        emailForStripeCheckout: action.payload.email,
      };
    case USER_TYPES.ADD_USER:
      return {
        ...state,
        emailForStripeCheckout: null,
        user: action.payload.user
      }
    case USER_TYPES.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default UserReducer;
