export const ROOT_ROUTES = {
  HOME: 'home',
  AUTH: 'auth',
  CHECKOUT: 'checkout',
  SUBSCRIPTION: 'subscription',
  ACCOUNT_MANAGEMENT: 'account-management',
  NEW_SUBSCRIBE_FLOW: 'subscribe',
};

export const ACCOUNT_MANAGEMENT_ROUTES = {
  BASE: 'account-management',
  PAYMENT: 'payment-method',
};

export const AUTH_ROUTES = {
  BASE: '/auth',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  VERIFY_EMAIL: '/verify-email',
  WEB_AUTH: '/web-auth',
};

export const HOME_ROUTES = {
  BASE: 'home',
  SHOP: 'shop',
  CART: 'cart',
  COURSES: 'courses',
};

export const CART_ROUTES = {
  BASE: '/cart',
};

export const CHECKOUT_ROUTES = {
  BASE: 'checkout',
  BUYER_IDENTIFICATION: 'buyer-identification',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const SUBSCRIPTION_ROUTES = {
  BASE: 'subscription',
  CHECKOUT: 'checkout',
  SELECT_METHOD: 'select-method',
};
export const NEW_SUBSCRIPTION_FLOW_ROUTE = {
  BASE: '',
  SELECT: 'select',
  SUCCESS: 'success',
};
