import React from 'react';
import { IStripeProduct } from '../../../../../shared/interfaces/StripeProduct';
import Present from '../../../../../assets/images/present.png';
import PresentWhite from '../../../../../assets/images/present_white.png';
interface IProps {
  product: IStripeProduct;
  savings?: number;
  isGift?: boolean;
  onSubscribe: (mode?: IStripeProduct) => void;
}
const SmallCard = (props: IProps) => {
  const { product, savings, isGift = false, onSubscribe } = props;

  const temp = product.price.unit_amount_decimal;
  const interval = product.mode;
  const pt = product && product.name.split(' ');
  const title = `${pt[0]} ${pt[1]}`;

  return (
    <div
      className={`flex relative flex-col gap-4 md:gap-10 rounded-lg border ${
        product.mode === 'month' ? 'bg-cardBg text-black' : 'bg-cardPurpleBg text-white'
      } ${isGift ? 'md:h-[280px]' : 'md:h-[210px]'} w-full p-6`}
    >
      {product.mode === 'year' && (
        <div className='absolute text-black px-3 py-2 -top-3 rounded text-xs bg-cardYellow'>SAVE {savings}%</div>
      )}
      <div className='text-xl font-bold'>{title}</div>
      <div>
        <span className='text-3xl font-bold'>
          ${temp?.slice(0, temp?.length - 2)}.{temp?.slice(temp?.length - 2)}
        </span>
        /{interval}
      </div>
      <img
        className='absolute bottom-2 left-5 w-8'
        src={product.mode === 'month' ? Present : PresentWhite}
        alt='Present Box'
      />
    </div>
  );
};

export default SmallCard;
