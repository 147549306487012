import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import { getApolloError } from '../../../../shared/utils/apolloError';
import { useHistory } from 'react-router-dom';
import RoundSpinner from '../../../AccountManagement/pages/MyAccount/Components/Spinner';
import REQUEST_PASSWORD_CHANGE_MUTATION from '../../../../graphql/auth/requestPasswordChange.mutation';

const ForgotPassword = () => {
  const history = useHistory();
  const [emailSent, changeEmailSent] = useState(false);
  const [email, changeEmail] = useState('');
  const [requestPasswordChange, { loading, error, data }] = useMutation(REQUEST_PASSWORD_CHANGE_MUTATION);

  const submitForm = (values: { email: string }) => {
    changeEmail(values.email);
    requestPasswordChange({ variables: values });
  };

  const ValidSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => submitForm(values),
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: ValidSchema,
  });

  useEffect(() => {
    if (data) {
      changeEmailSent(true);
    }
  }, [data]);

  return (
    <>
      <div className='space-y-2 mt-32 min-w-fit md:min-w-[500px] pb-36 md:pb-40'>
        <div className='flex min-h-full items-center justify-center px-2 sm:px-6 lg:px-8'>
          <div className='w-full space-y-8 border-2 border-gray-500/50 rounded-xl px-6 pb-6 text-white bg-black bg-opacity-50'>
            {!emailSent ? (
              <>
                <div>
                  <h2 className='text-left text-3xl font-semibold tracking-tight text-white py-6'>
                    Forgot your password?
                  </h2>
                  <p className='text-gray-400 text-sm md:text-md text-left'>
                    Don't worry. Just tell us the email address you registered with Co Create
                  </p>
                  {error && <Alert severity='error'>{getApolloError(error)}</Alert>}
                </div>
                <form className='mt-8 space-y-6' onSubmit={formik.handleSubmit}>
                  <div className='rounded-md shadow-sm space-y-3'>
                    <div className='text-left text-sm space-y-1'>
                      <label className='text-gray-500' htmlFor='email-address'>
                        Email address
                      </label>
                      <input
                        id='email-address'
                        name='email'
                        type='email'
                        autoComplete='email'
                        required
                        onChange={formik.handleChange}
                        className='relative block w-full appearance-none rounded-md border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                      />
                      {formik.errors['email'] && (
                        <span className='text-xs text-[#F44336]'>*{formik.errors['email']}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      type='submit'
                      disabled={!formik.isValid || loading}
                      className='group relative flex w-full justify-center items-center rounded-md border border-transparent bg-turquoise py-3 
                    px-4 text-sm font-medium text-white hover:bg-turquoiseClear focus:outline-none focus:ring-2 
                    focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
                    >
                      {loading && <RoundSpinner color={'text-black'} />}
                      SEND
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <div className='mt-10'>
                We have sent a reset password email to <span className='font-semibold'>{email}</span>
              </div>
            )}
            <div className='flex flex-col justify-center text-sm md:text-md'>
              <div
                className='w-full pt-2'
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/auth/login');
                }}
              >
                Go back to {''}
                <span className='text-turquoise hover:underline hover:underline-offset-3'>Login</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
