import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import GET_USER_PROFILE from '../graphql/user/getUserProfile.query';
import PrivateRoute from '../shared/components/PrivateRoute';
import PublicRoute from '../shared/components/PublicRoute';
import { AuthContext } from '../shared/context/AuthContext';
import { ACTIONS } from '../shared/context/AuthContext/Constants';
import AuthRouter from './Auth';
import NoMatch from './NoMatch';
import { AUTH_ROUTES, ROOT_ROUTES } from '../shared/constants/routes';
import AccountManagementRouter from './AccountManagement';
import NewSubscriptionFlowRouter from './NewSubscriptionFlow';
import CheckoutRouter from './Checkout';
import Plans from './AccountManagement/pages/Subscription/Plan';
// import { Container, styled } from '@material-ui/core';
// import HomeRouter from './Home';
// import SubscriptionRouter from './Subscription';
// import NewSubscriptionFlow from './NewSubscriptionFlow';
// import Login from './Auth/pages/Login';

const Root = () => {
  const [getUser, { data: getUserData }] = useLazyQuery(GET_USER_PROFILE);
  const { dispatch } = useContext(AuthContext);
  const history = useHistory();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const { pathname, search } = window.location;
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get('meditationId');

    console.log(pathname, id)
    if (id) {
      window.location.href = 'cocreate://meditation/' + id;
    }
  }, [history]);

  useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token, getUser]);

  useEffect(() => {
    if (getUserData) {
      const temp = get(getUserData, 'UserProfile', null);
      if (temp) {
        dispatch({ type: ACTIONS.SET_USER, user: temp });
        dispatch({ type: ACTIONS.SET_TOKEN, token });
        if (temp.stripe && temp.stripe.status === 'PENDING') {
          //TODO: CHECK IF SUB CHANGED
        }
      }
    }
  }, [getUserData]);

  return (
    <Router>
      <Switch>
        <PublicRoute path={`/${ROOT_ROUTES.AUTH}`}>
          <AuthRouter />
        </PublicRoute>
        <PublicRoute path={`/${ROOT_ROUTES.NEW_SUBSCRIBE_FLOW}`}>
          <NewSubscriptionFlowRouter />
        </PublicRoute>
        <PublicRoute path={`/${ROOT_ROUTES.CHECKOUT}`}>
          <CheckoutRouter />
        </PublicRoute>
        <PrivateRoute path={`/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}`}>
          <AccountManagementRouter />
        </PrivateRoute>
        <Route exact path='/'>
          <Redirect to={{ pathname: `/${ROOT_ROUTES.AUTH}${AUTH_ROUTES.LOGIN}` }} />
        </Route>
        <Route path='*'>
          <AuthRouter />
        </Route>
        {/* <PublicRoute path={`/${ROOT_ROUTES.HOME}`}>
          <HomeRouter />
        </PublicRoute> */}
        {/* <PrivateRoute path={`/${ROOT_ROUTES.CHECKOUT}`}>
          <CheckoutRouter/>
        </PrivateRoute> */}
        {/* <PrivateRoute path={`/${ROOT_ROUTES.SUBSCRIPTION}`}>
          <SubscriptionRouter/>
        </PrivateRoute> */}
      </Switch>
    </Router>
  );
};

export default Root;

/**
 * For gifting
 * https://app-dev.brookesnow.com/checkout
 * For subscription flow
 * https://app-dev.brookesnow.com/subscribe?plan=annual
   https://app-dev.brookesnow.com/subscribe?plan=monthly
 */
