const getApolloError = (error: any) => {
  console.log('error:', error)
  if(!error){
    return null
  }
  if (error.graphQLErrors) {
    if (error.graphQLErrors[0] && error.graphQLErrors[0].details && error.graphQLErrors[0].details.length > 0) {
      return error.graphQLErrors[0].details[0];
    }
  }
  if (error.message) {
    return error.message;
  }
};


export { getApolloError };
