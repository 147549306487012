import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { ACTIONS } from '../../context/AuthContext/Constants';
import { AUTH_ROUTES, ROOT_ROUTES } from '../../constants/routes';
import logo from '../../../assets/images/infinity-white.png';
import Video from '../../../assets/video/co-create-background.mp4';
import './styles.css';

const HeaderV2 = () => {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);

  const handleAccount = () => {
    history.push(`/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}`);
  };

  const handleLogout = () => {
    dispatch({ type: ACTIONS.LOGOUT });
    history.push(`/${ROOT_ROUTES.AUTH}${AUTH_ROUTES.LOGIN}`);
  };

  return (
    <div
      className='relative h-[85px] flex flex-row first-line:justify-between text-white mx-auto
    bg-cover bg-center bg-[url("./App/assets/images/bg.png")]'
    >
      {/* <div className='video-docker absolute top-0 left-0 w-full h-20 overflow-hidden'>
        <video className='min-w-full min-h-full absolute object-cover' src={Video} autoPlay={false} muted loop></video>
      </div> */}
      <div className='flex flex-row justify-between items-center mx-4 md:mx-20 w-full'>
        <div className='z-20 flex flex-col items-center justify-center' onClick={() => console.log}>
          <img className='mx-auto w-10 md:w-14' src={logo} alt='Co Create' />
          <h2 className='font-semibold text-sm md:text-base py-1'>Co Create</h2>
        </div>
        <div className='z-20 flex flex-row gap-3 font-light'>
          <h2 className='text-sm hover:underline hover:underline-offset-4 cursor-pointer' onClick={handleAccount}>
            My Account
          </h2>
          <h2 className='text-sm'>|</h2>
          <h2 className='text-sm hover:underline hover:underline-offset-4 cursor-pointer' onClick={handleLogout}>
            Sign Out
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HeaderV2;
