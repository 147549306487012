import {gql} from "@apollo/client";
import UserFragment from "../fragments/User.fragment";

export const GET_USER_PROFILE = gql`
    query UserProfile{
        UserProfile {
            ...User
        }
    }
    ${UserFragment.fragment}
`;

export default GET_USER_PROFILE;
