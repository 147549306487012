import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PublicRoute from '../../shared/components/PublicRoute';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Register from './pages/Register';
import VerifyEmail from './pages/VerifyEmail';

import { AUTH_ROUTES } from '../../shared/constants/routes';
import VerifyWebAuth from './pages/VerifyWebAuth';
import LayoutPage from './components/Layout/LayoutPage';

const AuthRouter = () => {
  let { path } = useRouteMatch();

  return (
    <LayoutPage>
      <Switch>
        <PublicRoute path={`${path}${AUTH_ROUTES.CHANGE_PASSWORD}/:token`}>
          <ChangePassword />
        </PublicRoute>
        <PublicRoute exact path={`${path}${AUTH_ROUTES.WEB_AUTH}/:token`}>
          <VerifyWebAuth />
        </PublicRoute>
        <PublicRoute exact path={`${path}${AUTH_ROUTES.WEB_AUTH}/:token/:urlToRedirect`}>
          <VerifyWebAuth />
        </PublicRoute>
        <PublicRoute path={`${path}${AUTH_ROUTES.FORGOT_PASSWORD}`}>
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute exact path={`${path}${AUTH_ROUTES.VERIFY_EMAIL}/:token`}>
          <VerifyEmail />
        </PublicRoute>
        <PublicRoute exact path={`${path}${AUTH_ROUTES.LOGIN}`}>
          <Login />
        </PublicRoute>
        <PublicRoute exact path={`${path}${AUTH_ROUTES.LOGIN}/:toRedirect`}>
          <Login />
        </PublicRoute>
        <PublicRoute exact path={`${path}${AUTH_ROUTES.REGISTER}`}>
          <Register />
        </PublicRoute>
        <PublicRoute path='*'>
          <Login />
        </PublicRoute>
      </Switch>
    </LayoutPage>
  );
};

export default AuthRouter;
