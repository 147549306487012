import {combineReducers} from 'redux'
import ShoppingCartReducer from './shoppingCart.reducer'
import UserReducer from "./user.reducer";

const reducer = combineReducers({
  shoppingCart: ShoppingCartReducer,
  user: UserReducer
})

export default reducer
