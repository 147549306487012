import {gql} from '@apollo/client';


const PaymentMethodCardChecksFragment = {
  fragment: gql`
      fragment PaymentMethodCardChecks on PaymentMethodCardChecks{
          address_line1_check
          address_postal_code_check
          cvc_check
      }
  `

}
export default PaymentMethodCardChecksFragment;
