import {gql} from "@apollo/client";
import PaymentMethodFragment from "../fragments/PaymentMethod.fragment";


export const GET_PAYMENT_METHOD = gql`
    query retrieveUserPaymentMethods($userId: String) {
        retrieveUserPaymentMethods(userId: $userId) {
             paymentMethods {...PaymentMethod}
             defaultPaymentSub 
        }
    }
    ${PaymentMethodFragment.fragment}
`;

export default GET_PAYMENT_METHOD;
