import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getApolloError } from '../../../../shared/utils/apolloError';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { Alert } from '@material-ui/lab';
import LayoutPage from '../../components/Layout/LayoutPage';
import RoundSpinner from '../../../AccountManagement/pages/MyAccount/Components/Spinner';
import REGISTER_MUTATION from '../../../../graphql/auth/register.mutation';
import { get } from 'lodash';
import { ACTIONS } from '../../../../shared/context/AuthContext/Constants';
import { ROOT_ROUTES } from '../../../../shared/constants/routes';
import { AuthContext } from '../../../../shared/context/AuthContext';
import GET_USER_PROFILE from '../../../../graphql/user/getUserProfile.query';
import LOGIN_MUTATION from '../../../../graphql/auth/login.mutation';

const AFFILIATLY_ID = process.env.REACT_APP_AFFILIATLY_ID;

function useQueryReactRouterDom() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface IProps {
  subParams?: any;
  onEvent?: (ev?: string) => void;
}

const Register = (props: IProps) => {
  const { subParams } = props;

  const searchParams = new URLSearchParams(useLocation().search);
  const affiliateId = searchParams.get('aff');

  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const reactRouterQuery = useQueryReactRouterDom();

  const [register, { loading: signLoading, error: registerError }] = useMutation(REGISTER_MUTATION);
  const [login, { error: loginError, loading: loginLoading }] = useMutation(LOGIN_MUTATION);
  const [getUser, { data: userData, error: userError, loading: userLoading }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
  });

  const error = loginError || registerError || userError;
  const loading = loginLoading || signLoading || userLoading;

  const getAffiliatlyCookieData = () => {
    const name = 'affiliatly_v3';
    const cookies = document.cookie.split(';');
    let affiliatlyCookie = null;
    for (const cookie of cookies) {
      if (cookie.trim().indexOf(name) === 0) {
        affiliatlyCookie = cookie.substring(name.length + 1);
        break;
      }
    }

    if (affiliatlyCookie == null) {
      return null;
    }

    const userID = parseInt(affiliatlyCookie.match(/id_user=([^&]+)/)[1]);
    const hash = affiliatlyCookie.match(/id_token=([^&]+)/)[1];

    return {
      userID,
      hash,
    };
  };

  const autoLogin = (values: { email: string; password: string }) => {
    login({ variables: values })
      .then((res) => {
        if (!res.errors) {
          const token = get(res, 'data.Login.jwtBearer');
          const refreshToken = get(res, 'data.Login.jwtRefresh');
          if (token && refreshToken) {
            dispatch({ type: ACTIONS.LOGIN, token, refreshToken });
            getUser();
          }
        }
      })
      .catch();
  };

  const onSubmit = (values: any) => {
    const errorMessage = '';

    const variables = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    };

    if (affiliateId) {
      const cookie = getAffiliatlyCookieData();
      if (cookie == null) {
        console.error('affiliatly cookie not set');
      } else {
        variables['affiliateId'] = parseInt(affiliateId);
        variables['affiliatlyUserId'] = cookie.userID;
        variables['affiliatlyHash'] = cookie.hash;
      }
    }

    register({ variables }).then((reg) => {
      if (reg?.errors) return;
      autoLogin({ email: values.email, password: values.password });
    });
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!values.confirmEmail) {
      errors.confirmEmail = 'Required';
    } else if (values.email !== values.confirmEmail) {
      errors.confirmEmail = "Emails don't match";
    }
    if (!values.password) {
      errors.password = 'Required';
    } else if (!values.repeatPassword) {
      errors.repeatPassword = 'Required';
    } else if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "Passwords don't match";
    }
    if (!values.agree) {
      errors.agree = 'Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      password: '',
      repeatPassword: '',
      agree: false,
    },
    onSubmit,
    validate,
  });

  useEffect(() => {
    if (affiliateId) {
      const affiliatlyTrackingScript = document.createElement('script');
      affiliatlyTrackingScript.src = `https://static.affiliatly.com/v3/affiliatly.js?affiliatly_code=${AFFILIATLY_ID}`;
      affiliatlyTrackingScript.async = true;
      document.body.appendChild(affiliatlyTrackingScript);
    }
  }, [affiliateId]);

  useEffect(() => {
    if (userData) {
      const temp = get(userData, 'UserProfile', null);
      if (temp) {
        dispatch({ type: ACTIONS.SET_USER, user: temp });
        const plan = reactRouterQuery.get('plan');
        const urlToRedirect = !plan
          ? `/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}`
          : `/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}?plan=${plan}`;
        history.push(urlToRedirect);
      }
    }
  }, [userData]);

  return (
    <>
      <div className='h-full space-y-2 mt-10 w-full'>
        <div className='flex min-h-full items-center justify-center px-2 sm:px-6 lg:px-8'>
          <div
            className='w-full space-y-8 border-2 border-gray-500/50 rounded-xl px-6 md:px-12 pb-6 text-white bg-black 
            bg-opacity-50'
          >
            {/* {reactRouterQuery.get('plan') ? (
              <div className='text-center text-white'>
                <h2 className='text-left text-3xl font-semibold tracking-tight py-6'>
                  Step 2: Choose Your <br />
                  Subscription Plan
                </h2>
                <p className='text-gray-400 text-sm md:text-md text-left font-light'>Let's get you started!</p>
              </div>
            ) : ( */}
            <div>
              <h2 className='text-left text-3xl font-semibold tracking-tight text-white py-6'>Create an Account</h2>
              <p className='text-gray-400 text-sm md:text-md text-left font-light'>Let's get you started!</p>
            </div>
            {/* )} */}
            {error && <Alert severity='error'>{getApolloError(error)}</Alert>}
            <form className='mt-8 space-y-6' onSubmit={formik.handleSubmit}>
              <div className='rounded-md shadow-sm space-y-2 md:space-y-1'>
                <div className='text-left space-y-2'>
                  <input
                    name='firstName'
                    type='text'
                    required
                    onChange={formik.handleChange}
                    placeholder='First Name'
                    className='relative block w-full appearance-none rounded-md border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {/* {formik.errors['firstName'] && (
                      <span className='text-xs text-[#F44336]'>*{formik.errors['firstName']}</span>
                    )} */}
                </div>
                <div className='text-left space-y-1'>
                  <input
                    name='lastName'
                    type='text'
                    required
                    onChange={formik.handleChange}
                    placeholder='Last Name'
                    className='relative block w-full appearance-none rounded-md border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {/* {formik.errors['lastName'] && (
                      <span className='text-xs text-[#F44336]'>*{formik.errors['lastName']}</span>
                    )} */}
                </div>
                <div className='text-left space-y-1'>
                  <input
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    onChange={formik.handleChange}
                    placeholder='Email Address'
                    className='relative block w-full appearance-none rounded-md border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {formik.errors['email'] && <span className='text-xs text-[#F44336]'>*{formik.errors['email']}</span>}
                </div>
                <div className='text-left space-y-1'>
                  <input
                    name='confirmEmail'
                    type='email'
                    autoComplete='email'
                    required
                    onChange={formik.handleChange}
                    placeholder='Confirm Email'
                    className='relative block w-full appearance-none rounded-md border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {formik.errors['confirmEmail'] && (
                    <span className='text-xs text-[#F44336]'>*{formik.errors['confirmEmail']}</span>
                  )}
                </div>
                <div className='text-left space-y-1'>
                  <input
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    onChange={formik.handleChange}
                    placeholder='Password'
                    className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {formik.errors['password'] && (
                    <span className='text-xs text-[#F44336]'>*{formik.errors['password']}</span>
                  )}
                </div>
                <div className='text-left space-y-1'>
                  <input
                    name='repeatPassword'
                    type='password'
                    autoComplete='current-password'
                    required
                    onChange={formik.handleChange}
                    placeholder='Confirm Password'
                    className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {/* {formik.errors['repeatPassword'] && (
                      <span className='text-xs text-[#F44336]'>*{formik.errors['repeatPassword']}</span>
                    )} */}
                </div>
              </div>
              <div className='flex items-center'>
                <input
                  id='agree'
                  name='agree'
                  type='checkbox'
                  required
                  onChange={formik.handleChange}
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
                />
                <label htmlFor='agree' className='ml-2 block md:text-sm text-xs text-white'>
                  I agree to Co Create's{' '}
                  <a
                    href='https://brookesnow.com/privacy-policy'
                    target='_blank'
                    className='text-indigo-500 underline'
                    rel='noreferrer'
                  >
                    Terms of service
                  </a>
                </label>
                {/* {formik.errors['agree'] && <label className='text-xs text-[#F44336]'>*{formik.errors['agree']}</label>} */}
              </div>
              <div>
                <button
                  type='submit'
                  disabled={!formik.isValid || loading}
                  className='group relative flex w-full justify-center rounded-md border border-transparent bg-turquoise py-3 
                    px-4 text-sm font-medium text-white hover:bg-turquoiseClear focus:outline-none focus:ring-2 
                    focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
                >
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3`}>
                    {loading ? (
                      <RoundSpinner color={'text-white'} />
                    ) : (
                      <LockClosedIcon
                        className={`h-5 w-5 text-turquoise group-hover:text-indigo-400`}
                        aria-hidden='true'
                      />
                    )}
                  </span>
                  CREATE MY ACCOUNT
                </button>
              </div>
            </form>
            <div className='flex flex-col justify-center text-sm md:text-md'>
              <div
                className='w-full'
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Already have an account? ? {''}
                <span className='text-turquoise hover:underline hover:underline-offset-3'>Login Here</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </LayoutPage> */}
    </>
  );
};

export default Register;
