import { gql } from '@apollo/client';

export default gql`
    mutation login($email: String!, $password: String!) {
        Login(input: {email: $email, password: $password}) {
            email
            jwtBearer
            jwtRefresh
        }
    }
`;