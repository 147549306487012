import React, { useReducer } from 'react';
import AuthContext from './AuthContext';
import { ACTIONS } from './Constants';

const AuthProvider = (props: any) => {
  const [state, dispatch] = useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case ACTIONS.SET_TOKEN:
          return {
            ...prevState,
            token: action.token,
            isLoading: false,
          };
        case ACTIONS.LOGIN:
          localStorage.setItem('token', action.token);
          localStorage.setItem('refreshToken', action.refreshToken);
          return {
            ...prevState,
            token: action.token,
            refreshToken: action.refreshToken,
            isLoading: true,
          };
        case ACTIONS.SET_USER:
          return {
            ...prevState,
            user: action.user,
          };
        case ACTIONS.SET_LOADING:
          return {
            ...prevState,
            isLoading: action.isLoading,
          };
        case ACTIONS.SET_EMAIL_STRIPE_CHECKOUT:
          return {
            ...prevState,
            emailForStripeCheckout: action.payload.emailForStripeCheckout,
          };
        case ACTIONS.LOGOUT:
          localStorage.removeItem('token');
          return {
            ...prevState,
            token: null,
            refreshToken: null,
            isLoading: false,
            user: null,
          };
        default:
          throw new Error();
      }
    },
    {
      user: null,
      emailForStripeCheckout: null,
      isLoading: true,
      token: null,
      refreshToken: null,
    },
  );

  return <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
