import {gql} from '@apollo/client';

const selection = `
  id
    billing_cycle_anchor
    collection_method
    created
    current_period_end
    current_period_start
    start_date
    status`;

const StripeSubscriptionFragment = {
  fragment: gql`
      fragment StripeSubscription on StripeSubscription{
          ${selection}
      }
  `
}
export default StripeSubscriptionFragment;