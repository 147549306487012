import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLazyQuery, useMutation } from '@apollo/client';
import LOGIN_MUTATION from '../../../../graphql/auth/login.mutation';
import { AuthContext } from '../../../../shared/context/AuthContext';
import { ACTIONS } from '../../../../shared/context/AuthContext/Constants';
import { get } from 'lodash';
import { getApolloError } from '../../../../shared/utils/apolloError';
import { Alert } from '@material-ui/lab';
import GET_USER_PROFILE from '../../../../graphql/user/getUserProfile.query';

import { ROOT_ROUTES } from '../../../../shared/constants/routes';
import { EMAIL_REGEX } from '../../../../shared/utils/emailRegex';

import './styles.css';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import RoundSpinner from '../../../AccountManagement/pages/MyAccount/Components/Spinner';

function useQueryReactRouterDom() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface IProps {
  subParams?: any;
  onEvent?: (ev?: string) => void;
}

const Login = (props: IProps) => {
  const { subParams } = props;
  const history = useHistory();
  const params = useParams();
  const { dispatch } = useContext(AuthContext);
  const reactRouterQuery = useQueryReactRouterDom();

  const [toRedirect, setToRedirect] = useState<string>(null);
  const [login, { error: loginError, loading: loginLoading }] = useMutation(LOGIN_MUTATION);
  const [getUser, { data: getUserData, error: getUserError, loading: getUserLoading }] = useLazyQuery(
    GET_USER_PROFILE,
    { fetchPolicy: 'network-only' },
  );

  const loading = loginLoading || getUserLoading;
  const error = loginError || getUserError;

  const onSubmit = (values: { email: string; password: string }) => {
    login({ variables: values })
      .then(async (res) => {
        if (!res.errors) {
          const token = get(res, 'data.Login.jwtBearer');
          const refreshToken = get(res, 'data.Login.jwtRefresh');
          if (token && refreshToken) {
            getUser();
            dispatch({ type: ACTIONS.LOGIN, token, refreshToken });
          }
        }
      })
      .catch();
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = 'Invalid email';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
    validate,
  });

  const redirect = () => {
    const returnTo = reactRouterQuery.get('returnTo');
    const plan = reactRouterQuery.get('plan');
    if (returnTo) {
      history.push(`/${returnTo}`);
    } else {
      const urlToRedirect = !plan
        ? `/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}`
        : `/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}?plan=${plan}`;
      history.push(urlToRedirect);
    }
  };

  useEffect(() => {
    if (getUserData) {
      const temp = get(getUserData, 'UserProfile', null);
      if (temp) {
        dispatch({ type: ACTIONS.SET_USER, user: temp });
        redirect();
      }
    }
  }, [getUserData]);

  useEffect(() => {
    localStorage.clear();
    dispatch({ type: ACTIONS.LOGOUT });
  }, [dispatch]);

  return (
    <>
      <div className='h-full space-y-2 mt-10 w-full'>
        <div className='flex min-h-full items-center justify-center px-2 sm:px-6 lg:px-8'>
          <div className='w-full space-y-8 border-2 border-gray-500/50 rounded-xl px-6 pb-6 text-white bg-black bg-opacity-50'>
            {subParams ? (
              <div className='text-center text-white'>
                <h2 className='text-left text-3xl font-semibold tracking-tight  py-6'>{subParams.title}</h2>
                {/* <p className='text-sm md:text-lg'>{subParams.subtitle} </p> */}
              </div>
            ) : (
              <>
                <h2 className='text-left text-3xl font-semibold tracking-tight text-white py-6'>Login</h2>
                <p className='text-gray-400 text-sm md:text-md text-left'>
                  Welcome Back! Please login to your account.
                </p>
              </>
            )}
            <div>{error && <Alert severity='error'>{getApolloError(error)}</Alert>}</div>
            <form className='mt-8 space-y-6' onSubmit={formik.handleSubmit}>
              <div className='rounded-md shadow-sm space-y-3'>
                <div className='text-left text-sm space-y-1'>
                  <label className='text-gray-500' htmlFor='email-address'>
                    Email address
                  </label>
                  <input
                    id='email-address'
                    name='email'
                    type='email'
                    autoComplete='email'
                    required
                    onChange={formik.handleChange}
                    className='relative block w-full appearance-none rounded-md border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {formik.errors['email'] && <span className='text-xs text-[#F44336]'>*{formik.errors['email']}</span>}
                </div>
                <div className='text-left text-sm space-y-1'>
                  <label className='text-gray-500' htmlFor='password'>
                    Password
                  </label>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    onChange={formik.handleChange}
                    className='relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                  {formik.errors['password'] && (
                    <span className='text-xs text-[#F44336]'>*{formik.errors['password']}</span>
                  )}
                </div>
              </div>
              <div>
                <button
                  type='submit'
                  disabled={!formik.isValid || loading}
                  className='group relative flex w-full justify-center rounded-md border border-transparent bg-turquoise py-3 
                    px-4 text-sm font-medium text-white hover:bg-turquoiseClear focus:outline-none focus:ring-2 
                    focus:ring-turquoise focus:ring-offset-2 transform hover:-translate-y-1 transition ease-in-out delay-150'
                >
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3`}>
                    {loading ? (
                      <RoundSpinner color={'text-white'} />
                    ) : (
                      <LockClosedIcon
                        className={`h-5 w-5 text-turquoise group-hover:text-indigo-400`}
                        aria-hidden='true'
                      />
                    )}
                  </span>
                  SIGN IN
                </button>
              </div>
            </form>
            <div className='flex flex-col justify-center text-sm md:text-md'>
              <div className='w-full' onClick={() => history.push('/auth/forgot-password')}>
                <p className='text-white hover:text-turquoise hover:underline hover:underline-offset-3'>
                  Forgot My Password
                </p>
              </div>

              <div
                className='w-full pt-6'
                onClick={(e) => {
                  e.preventDefault();
                  const plan = reactRouterQuery.get('plan');
                  history.push(!plan ? '/auth/register' : `/auth/register?plan=${plan}`);
                }}
              >
                Don't have an account? {''}
                <span className='text-turquoise hover:underline hover:underline-offset-3'>Register Here</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
