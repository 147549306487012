import React, { useEffect, useState } from 'react';
import AuthContainerForm from '../../components/AuthContainerForm';
import { useHistory, useParams } from 'react-router-dom';
import VERIFY_EMAIL from '../../../../graphql/auth/verifyEmail.mutation';
import { useMutation } from '@apollo/client';
import Title from '../../../../shared/components/styled/Title';
import Subtitle from '../../../../shared/components/styled/Subtitle';
import Button from '../../../../shared/components/Button';
import VerifyEmailError from './components/VerifyEmailError';
import { LinearProgress } from '@material-ui/core';
import { getApolloError } from '../../../../shared/utils/apolloError';
import ContainerWhiteBackground from '../../../../shared/components/ContainerWhiteBackground';

const VerifyEmail = () => {
  const params = useParams();
  const history = useHistory();

  const [verifyToken, setVerifyToken] = useState<string>(null);
  const [emailVerified, changeEmailVerified] = useState(false);
  const [
    verifyEmail,
    { loading, data: verifyEmailData, error: verifyEmailError },
  ] = useMutation(VERIFY_EMAIL);

  useEffect(() => {
    if (verifyToken) {
      verifyEmail({
        variables: {
          token: verifyToken,
        },
      }).catch((e) => console.log(e));
    }
  }, [verifyToken]);

  useEffect(() => {
    //@ts-ignore
    if (params && params.token) {
      //@ts-ignore
      setVerifyToken(params.token);
    }
  }, [params]);

  useEffect(() => {
    if (verifyEmailData) {
      changeEmailVerified(true);
    }
  }, [verifyEmailData]);

  return (
    <ContainerWhiteBackground>
      <AuthContainerForm>
        {verifyEmailError ? (
          getApolloError(verifyEmailError) === 'Email already verified' ? (
            <>
              <Title>Your Email was already Verified!</Title>
              <Subtitle>
                You can login to your account through the mobile app or web app.
              </Subtitle>
              <Button onClick={() => history.push('/auth/login')}>
                LOGIN WEB
              </Button>
            </>
          ) : (
            <VerifyEmailError error={verifyEmailError} />
          )
        ) : (
          <>
            {emailVerified && !loading ? (
              <>
                <Title>Email Verified!</Title>
                <Subtitle>You can login to your account.</Subtitle>
                <Button onClick={() => history.push('/auth/login')}>
                  LOGIN WEB
                </Button>
              </>
            ) : (
              <>
                <Title>Verifying email</Title>
                <LinearProgress />
              </>
            )}
          </>
        )}
      </AuthContainerForm>
    </ContainerWhiteBackground>
  );
};

export default VerifyEmail;
