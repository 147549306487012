import { gql } from '@apollo/client';

export const CHANGE_STRIPE_SUBSCRIPTION = gql`
  mutation changeStripeSubscription($input: ChangeSubscriptionInput) {
    changeStripeSubscription(input: $input) {
      message
    }
  }
`;

export default CHANGE_STRIPE_SUBSCRIPTION;
