import styled from 'styled-components';

const AuthFooter = styled.a`
font-family: 'evolve-regular';
text-align: center;
align-self:center;
color:#4E4E4E;
font-size: 12px;
max-width:160px;
`;


export default AuthFooter;