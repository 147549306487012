import { ApolloError } from '@apollo/client';
import React from 'react';
import Subtitle from '../../../../../../shared/components/styled/Subtitle';
import Title from '../../../../../../shared/components/styled/Title';
import ResendEmailVerificationForm from '../../../../components/ResendEmailVerificationForm';


interface IProps {
    error?: string | ApolloError;
}
const VerifyEmailError = (props: IProps) => {
    return (
        <div>
            <ResendEmailVerificationForm
                error={props.error}
                header={
                    <>
                        <Title>Email Verification Error</Title>
                        <Subtitle>There was a problem with your email verification. Please try again.</Subtitle>
                    </>
                }
            />
        </div>
    )
};


export default VerifyEmailError;