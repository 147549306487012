import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROOT_ROUTES } from '../../../../shared/constants/routes';
import { IUser } from '../../../../shared/interfaces/User';
import NewSubscription from './Subscription';

interface IProps {
  userInfo: IUser;
}

const Plans = (props: IProps) => {
  const { userInfo } = props;
  const history = useHistory();

  const handleEvent = (e: string) => {
    history.replace(`/${ROOT_ROUTES.ACCOUNT_MANAGEMENT}`);
  };

  return <NewSubscription user={userInfo} onEvent={handleEvent} />;
};

export default Plans;
