import { gql } from '@apollo/client';

const CHANGE_PASSWORD_MUTATION =  gql`
mutation changePassword($token: String!, $password: String!) {
        ChangePassword(input: {jwtPasswordReset: $token, password: $password}) {
            message
        }
    }
`;

export default CHANGE_PASSWORD_MUTATION;