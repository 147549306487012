import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NewCheckout from './pages/CheckoutNew';
import { AuthContext } from '../../shared/context/AuthContext';
import HeaderV2 from '../../shared/components/Header/Header';
// import { CHECKOUT_ROUTES } from '../../shared/constants/routes';
// import Error from './pages/Error';
// import Success from './pages/Success';

const CheckoutRouter = () => {
  let { path } = useRouteMatch();
  const { state } = useContext(AuthContext);

  useEffect(() => {
    console.log('Check General page');
  }, []);
  return (
    <Switch>
      <Route exact path={path}>
        <>
          <HeaderV2 />
          <NewCheckout user={state?.user} onEvent={null} />
        </>
      </Route>
      {/* <Route path={`${path}/${CHECKOUT_ROUTES.SUCCESS}`}>
        <Success />
      </Route>
      <Route path={`${path}/${CHECKOUT_ROUTES.ERROR}`}>
        <Error />
      </Route> */}
    </Switch>
  );
};

export default CheckoutRouter;
